import React, { useEffect, useState } from "react";
import axios from "axios";
import { navigate, graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  setSnackBarMsg,
  setLoading,
  addSubProject,
  setCurrency,
  setMetricOrUs,
  setDecimalLocale,
  setDisplayedAvgLeakSizeValues,
} from "../store/ducks/general";
import {
  userRoles,
  metricUsConversions,
} from "../components/utilities/utilities";
import styled from "styled-components";
import Layout from "../components/layout";
import Wrapper from "../components/layouts/standard-wrapper";
import Licenses from "../components/layouts/licenses-passwords";
import UserRoute from "../components/routes/userRoute";
import { BackButton } from "../components/buttons/buttons";

//#region Styles
const Checkbox = styled.label`
  display: block;
  height: 20px;
  width: 20px;
  position: relative;
  cursor: pointer;
  transform: scale(0.75);
  /* Reset browser default */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /* Create custom checkbox */
  .checkmark {
    border: 1px solid
      ${(props) => props.theme.variables.buttons.checkbox.border};
    position: absolute;
    inset: 0;
    height: 20px;
    width: 20px;
    transition: all 250ms;
  }
  &:hover input ~ .checkmark {
    border: 1px solid
      ${(props) => props.theme.variables.buttons.checkbox.hoverBorder};
  }
  input:checked ~ .checkmark {
    background: ${(props) => props.theme.variables.buttons.checkbox.checked};
    border: 1px solid
      ${(props) => props.theme.variables.buttons.checkbox.checked};
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const HeaderSection = styled.section`
  h1 {
    padding: 2rem 0 0 0;
  }
`;

const TabSection = styled.section`
  padding: 2rem;
  .tab-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-bottom: 1px solid ${(props) => props.theme.variables.charts.border};
  }
  #sections {
    font-weight: 500;
    font-size: 0.9375rem;
    padding: 0.75rem 2rem;
    font-weight: 600;
    text-align: center;
    color: ${(props) => props.theme.variables.color};
    background: none;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    &:hover {
      border-bottom: 2px solid
        ${(props) => props.theme.colors.primaryColors.white};
    }
  }
  .active {
    border-bottom: 2px solid ${(props) => props.theme.colors.primaryColors.blue} !important;
  }
  .card {
    background: ${(props) => props.theme.colors.uiPalette.dark.darkGray};
    border: 1px solid ${(props) => props.theme.colors.uiPalette.dark.highGray};
    border-radius: 3px;
    padding: 2rem 20rem 2rem 2rem;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    #sections {
      padding: 0.75rem 0rem;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    padding: 2rem 0 0 0;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    #sections {
      font-size: 0.75rem;
    }
  }
`;

const ContactTab = styled.section`
  padding: 2rem 5rem;
  display: flex;
  // grid-gap: 1.5rem;
  // grid-template-columns: repeat(0,0fr);
  // grid-template-areas:
  // "user communication"
  // "user account";
  position: relative;
  transition: all 250ms;
  p {
    margin: 0 0 1.15rem 0;
    line-height: 1.6;
  }
  h5 {
    margin: 0 0 1.5rem 0;
    font-size: 1.25rem;
  }
  .user {
    grid-area: user;
    align-self: start;
    position: relative;
  }
  .communication {
    grid-area: communication;
    align-self: start;
  }
  .account {
    grid-area: account;
    align-self: start;
  }
  .account-sticky {
    grid-area: account;
    align-self: start;
    position: absolute;
    width: 100%;
    inset: 0 auto auto 0;
  }
  .edit-button {
    font-size: 0.8125rem;
    position: absolute;
    letter-spacing: 1px;
    font-weight: 500;
    inset: 1.5rem 1.5rem auto auto;
    border: none;
    background: none;
    color: ${(props) => props.theme.variables.color};
    text-transform: uppercase;
    &:hover {
      color: ${(props) => props.theme.colors.secondaryColors.orange};
    }
  }
  form.preferences {
  }
  form.edit-info {
    input[type="text"] {
      background: none;
      border: none;
      color: ${(props) => props.theme.variables.color};
      border-bottom: 1px solid transparent;
      &::placeholder {
        color: ${(props) => props.theme.variables.color};
      }
      &:focus {
        color: ${(props) => props.theme.colors.secondaryColors.green};
        outline: none !important;
        border-bottom: 1px solid
          ${(props) => props.theme.colors.secondaryColors.green};
      }
    }
    label {
      margin: 0 0 1rem 0;
      display: flex;
      flex-flow: column;
      border: 1px solid ${(props) => props.theme.colors.uiPalette.dark.midGray};
      padding: 1rem;
    }
    label:last-child {
      margin: 0;
    }
    span {
      text-transform: uppercase;
      font-size: 0.8125rem;
      line-height: 1.6;
    }
  }
  #user-info,
  #edit-user-info {
    transition: all 400ms;
  }
  .address-list {
    margin: 0 0 1.15rem 0;
  }
  .contact-list {
  }
  .address-item,
  .contact-item,
  .email-item {
    font-size: 0.9375rem;
    line-height: 1.6;
  }
  .email-item {
    margin: 1.15rem 0 0 0;
  }
  .confirmations {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0.65rem 0;
    font-size: 0.9375rem;
  }
  .research-and-development {
    margin: 0.5rem 0 1.5rem 0;
  }
  .submit-row {
    margin: 1.5rem 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .save-notification {
    display: flex;
    align-items: center;
    padding: 0 0 0 1rem;
    font-size: 0.9375rem;
    transition: all 150ms ease-in-out;
    .icon {
      margin: 0 0 0 8px;
    }
  }
  .pending {
    opacity: 0;
    visibility: hidden;
  }
  .success {
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xl}) {
    padding: 2rem 0;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    padding: 2rem 0;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    padding: 2rem 0 0 0;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      "user"
      "account"
      "communication";
    .account-sticky {
      position: static;
    }
  }
`;

const LicensesTab = styled.section``;

const SettingsTab = styled.section`
  .settings-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.5rem;
    padding: 3rem;
    font-size: 15px;
    background-color: #212121;
  }
  .settings-header,
  .averageLeakSizeHeader {
    color: #646464;
    padding-bottom: 2rem;
    font-size: 18px;
  }
  .settings-options {
    display: grid;
    grid-template-columns: 170px 182px 119px;
  }
  .settings-options > input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button,
  input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: none;
  }
  .selected-option {
    font-size: 11px;
    border-radius: 2rem;
    padding: 5px 0px 5px 7px;
    margin-bottom: 1rem;
    color: white;
    background-color: #212121;
  }
  .cost-unit {
    color: white;
    margin-left: 5px;
    margin-top: 3px;
  }
  .button-row {
    display: flex;
    justify-content: flex-end;
  }
  .apply-button {
    position: relative;
    left: -14px;
    color: white;
    font-size: 16px;
    font-weight: 300;
    border-radius: 2rem;
    background-color: #102690;
    padding: 6px 28px;
    border: 1px solid white;
  }
  .reset-button-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px 0px;
  }
  .reset-button {
    display: flex;
    color: white;
    text-decoration: underline;
  }
  .reset-button:hover {
    color: red;
  }
`;
//#endregion

const LeakMetricInput = ({
  title,
  valueToUpdate,
  displayedPreviousValue,
  chooseFunction,
  selectedUnit,
}) => {
  return (
    <div className="settings-options">
      <div className="setting-type">{title}</div>
      <input
        className="selected-option"
        type="number"
        value={valueToUpdate}
        placeholder={`Previous Value ${displayedPreviousValue}`}
        onChange={chooseFunction}
      ></input>
      <span className="cost-unit">
        {selectedUnit === "Imperial US"
          ? `gal`
          : selectedUnit === "Standard Metric"
          ? `liter`
          : selectedUnit === "Metric Cubic Meters"
          ? `m³`
          : null}{" "}
        {`/ hour`}
      </span>
    </div>
  );
};

const Account = () => {
  const { t } = useTranslation();
  const { changeLanguage } = useI18next();
  const dispatch = useDispatch();

  const loginValues = useSelector((state) => state.general.loginValues);
  const decimalLocale = useSelector((state) => state.general.decimalLocale);
  const subProjectRecoverArr = JSON.parse(localStorage.getItem("subProjects"));
  const [licenses, setLicenses] = useState([]);

  // Remembers the selection options in Settings dropdown menu
  const rememberSelectedLanguage = window.localStorage.getItem(
    "gatsby-i18next-language"
  );
  const rememberStoredCurrency = JSON.parse(
    window.localStorage.getItem("general")
  )?.currency;
  const rememberStoredUnit = JSON.parse(
    window.localStorage.getItem("general")
  )?.isMetricOrUs;
  const rememberHydratedCurrency = JSON.parse(
    JSON.parse(window.localStorage.getItem("persist:root"))?.general
  )?.currency;
  const rememberHydratedUnit = JSON.parse(
    JSON.parse(window.localStorage.getItem("persist:root"))?.general
  )?.isMetricOrUs;

  const [selectedLanguage, setSelectedLanguage] = useState(
    rememberSelectedLanguage
  );
  const [selectedDecimalLocale, setSelectedDecimalLocale] =
    useState(decimalLocale);
  const [selectedCurrency, setSelectedCurrency] = useState(
    rememberStoredCurrency ? rememberStoredCurrency : rememberHydratedCurrency
  );
  const [selectedUnit, setSelectedUnit] = useState(
    rememberStoredUnit ? rememberStoredUnit : rememberHydratedUnit
  );

  // Shows previous costs (per Project) from the database
  const [previousWaterCost, setPreviousWaterCost] = useState("");
  const [previousPowerCost, setPreviousPowerCost] = useState("");
  const [previousGreenhouseEmissions, setPreviousGreenhouseEmissions] =
    useState("");

  // Send new cost values (per project) to database
  const [displayedWaterCost, setDisplayedWaterCost] = useState("");
  const [displayedPowerCost, setDisplayedPowerCost] = useState("");
  const [displayedGreenhouseGas, setDisplayedGreenhouseGas] = useState("");
  const [project, setProject] = useState("");
  const [projectLeak, setProjectLeak] = useState("");

  // Only updates options that have changed, rather than all of them
  const [options, setOptions] = useState([]);

  const getData = async () => {
    try {
      const { data } = await axios.get(`/licenses`, {
        params: {
          id: loginValues.typeId,
          role: loginValues.role,
        },
      });
      setLicenses(data);
    } catch (err) {
      localStorage.removeItem("auth");
      localStorage.removeItem("subProjectData");
      localStorage.removeItem("subProjects");
      localStorage.removeItem("subMenu");
      localStorage.removeItem("ally-supports-cache");
      navigate("/");
    }
  };

  useEffect(async () => {
    if (loginValues && loginValues.token !== null) {
      try {
        if (
          loginValues.role === userRoles.ADMIN_USER ||
          loginValues.role === userRoles.PARTNER_USER
        ) {
          getData();
        }
      } catch (err) {
        localStorage.removeItem("auth");
        localStorage.removeItem("subProjectData");
        localStorage.removeItem("subProjects");
        localStorage.removeItem("subMenu");
        localStorage.removeItem("ally-supports-cache");
        navigate("/");
      }
    }
  }, [loginValues.token]);

  const goToSubProject = (subProjectData) => {
    dispatch(addSubProject(subProjectData.sub_project));
    window.localStorage.setItem("auth", JSON.stringify({ ...loginValues }));
    window.localStorage.setItem(
      "subProjectData",
      JSON.stringify(subProjectData)
    );
    navigate("../u-collect-dashboard/dashboard");
  };

  // Toggle display of active tab
  const [tabSection, setTabSection] = useState("settings");

  const buttons = [
    { name: t("system-settings"), id: "settings" },
    { name: t("leak-sizes"), id: "leak sizes" },
    { name: t("contact-information"), id: "contact" },
    { name: t("licenses-and-passwords"), id: "licenses" },
  ];

  const handleTabClick = (tabId) => {
    setTabSection(tabId);

    setProject("");

    setPreviousWaterCost("");
    setPreviousPowerCost("");
    setPreviousGreenhouseEmissions("");

    setDisplayedWaterCost("");
    setDisplayedPowerCost("");
    setDisplayedGreenhouseGas("");

    setProjectLeak("");
    setUserMain("");
    setUserService("");
    setUserCustomerSide("");
    setUserMeter("");
    setUserValve("");
    setUserHydrant("");
    setUserCustomerFitting("");
    setUserCurbstop("");

    dispatch(
      setDisplayedAvgLeakSizeValues({
        main: "",
        service: "",
        customerSide: "",
        meter: "",
        valve: "",
        hydrant: "",
        fitting: "",
        curbstop: "",
      })
    );
  };

  //#region Language Currency JSON
  const languageMap = [
    "English",
    "Romanian",
    "Portuguese PT",
    "Portuguese BR",
    "Français",
    "Polish",
    "Deutsch",
    "中国人",
    "čeština",
    "Italiano",
    "日本",
    "Español ES",
    "Español MEX",
    "Nederlands",
    "한국인",
    "Magyar",
  ];

  const langShortNames = [
    { short: "en", locale: "en-US" },
    { short: "rom", locale: "ro" },
    { short: "ptpt", locale: "pt-PT" },
    { short: "ptbr", locale: "pt-BR" },
    { short: "fr", locale: "fr" },
    { short: "pl", locale: "pl" },
    { short: "gr", locale: "de" },
    { short: "chinese", locale: "zh-CN" },
    { short: "czech", locale: "cs" },
    { short: "italian", locale: "it" },
    { short: "japanese", locale: "ja" },
    { short: "spanishES", locale: "es-ES" },
    { short: "spanishMEX", locale: "es-MX" },
    { short: "nederlands", locale: "nl" },
    { short: "korean", locale: "ko" },
    { short: "hungarian", locale: "hu" },
  ];
  const currencies = [
    "USD $",
    "CAD C$",
    "GBP £",
    "RON lei",
    "EUR €",
    "BRL R$",
    "PLN zł",
    "CNY ¥",
    "CZK Kč",
    "JPY ¥",
    "MXN $",
    "KRW ₩",
    "ARG $",
    "ZAR R",
    "BWP P",
    "HUF Ft",
  ];
  //#endregion

  //#region setSettings

  const chooseLanguage = (e) => {
    const selectedLanguageObject = langShortNames.find(
      (item) => item.short === e.target.value
    );
    setSelectedDecimalLocale(selectedLanguageObject.locale);
    setSelectedLanguage(selectedLanguageObject.short);
  };

  const chooseSettingsOption = (setterFunction) => (e) => {
    setterFunction(e.target.value);
  };

  const chooseCurrency = chooseSettingsOption(setSelectedCurrency);
  const chooseUnit = chooseSettingsOption(setSelectedUnit);
  const chooseWaterCost = chooseSettingsOption(setDisplayedWaterCost);
  const choosePowerCost = chooseSettingsOption(setDisplayedPowerCost);
  const chooseGreenhouseGas = chooseSettingsOption(setDisplayedGreenhouseGas);
  const chooseProject = chooseSettingsOption(setProject);

  const updateOptions = (newOptions) => {
    setOptions(newOptions);
  };

  const updateLocalStorage = (newOptions) => {
    if (JSON.stringify(newOptions) !== JSON.stringify(options)) {
      localStorage.setItem("options", JSON.stringify(newOptions));
    }
  };

  useEffect(() => {
    updateLocalStorage(options);
  }, [options]);
  //#endregion

  useEffect(() => {
    if (project) retrieveWaterPowerGreenCosts();
  }, [selectedUnit]);

  const retrieveWaterPowerGreenCosts = async () => {
    try {
      const res = await axios.get(`/base-values`, {
        params: {
          type: "id",
          userId: loginValues.userId,
          role: loginValues.role,
          subProjects: project,
        },
      });
      if (res.status === 200) {
        if (selectedUnit === "Imperial US") {
          setPreviousWaterCost(
            (res.data.water_cost / literToGallon).toFixed(6)
          );
          setDisplayedWaterCost(
            (res.data.water_cost / literToGallon).toFixed(6)
          );
        } else if (selectedUnit === "Metric Cubic Meters") {
          setPreviousWaterCost(
            (res.data.water_cost / literToMetricCube).toFixed(6)
          );
          setDisplayedWaterCost(
            (res.data.water_cost / literToMetricCube).toFixed(6)
          );
        } else {
          setPreviousWaterCost(Number(res.data.water_cost).toFixed(6));
          setDisplayedWaterCost(Number(res.data.water_cost).toFixed(6));
        }
        setPreviousPowerCost(res.data.power_cost);
        setPreviousGreenhouseEmissions(res.data.greenhouse_gas_emissions);
        setDisplayedPowerCost(res.data.power_cost);
        setDisplayedGreenhouseGas(res.data.greenhouse_gas_emissions);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateWaterPowerGreenCosts = async () => {
    let convertedWaterCost;

    if (selectedUnit === "Imperial US") {
      convertedWaterCost = displayedWaterCost / gallonToLiter;
    } else if (selectedUnit === "Metric Cubic Meters") {
      convertedWaterCost = displayedWaterCost / metricCubeToLiter;
    } else {
      convertedWaterCost = displayedWaterCost;
    }

    try {
      const res = await axios.post(`/base-values`, {
        data: {
          water_cost: convertedWaterCost,
          power_cost: displayedPowerCost,
          treatment_cost: "",
          greenhouse_gas_emissions: displayedGreenhouseGas,
          comments_and_remarks: "",
          sub_project: project,
          energy_per_liter: 0.0006,
        },
        role: loginValues.role,
        userId: loginValues.userId,
      });
      dispatch(setLoading(true));
      if (res.status === 200) {
        setTimeout(() => {
          retrieveWaterPowerGreenCosts();
          dispatch(setLoading(false));
          dispatch(setSnackBarMsg(t("settings-saved")));
        }, 500);
      }
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
      dispatch(setSnackBarMsg(t("settings-error")));
    }
  };

  const handleClick = () => {
    updateOptions();
    changeLanguage(selectedLanguage);
    dispatch(setDecimalLocale(selectedDecimalLocale));
    dispatch(setCurrency(selectedCurrency));
    dispatch(setMetricOrUs(selectedUnit));

    window.localStorage.setItem("gatsby-i18next-language", selectedLanguage);
    window.localStorage.setItem(
      "general",
      JSON.stringify({
        isMetricOrUs: selectedUnit,
        currency: selectedCurrency,
      })
    );

    if (
      displayedWaterCost === "" ||
      displayedPowerCost === "" ||
      displayedGreenhouseGas === ""
    ) {
      dispatch(setSnackBarMsg(t("settings-saved-basic")));
      return;
    } else {
      updateWaterPowerGreenCosts();
    }
  };

  // Beginning of AVG LEAK //
  const displayedAvgLeakSizeValues = useSelector(
    (state) => state.general.displayedAvgLeakSizeValues
  );

  const [userMain, setUserMain] = useState("");
  const [userService, setUserService] = useState("");
  const [userCustomerSide, setUserCustomerSide] = useState("");
  const [userMeter, setUserMeter] = useState("");
  const [userValve, setUserValve] = useState("");
  const [userHydrant, setUserHydrant] = useState("");
  const [userCustomerFitting, setUserCustomerFitting] = useState("");
  const [userCurbstop, setUserCurbstop] = useState("");

  const {
    literToGallon,
    literToMetricCube,
    gallonToLiter,
    metricCubeToLiter,
    yearToHour,
  } = metricUsConversions;

  const unitToLiterConversions = {
    "Imperial US": gallonToLiter,
    "Standard Metric": 1,
    "Metric Cubic Meters": metricCubeToLiter,
  };

  const handleLeakTypeConversion = (
    data,
    literToUnit,
    yearToHour,
    precision
  ) => {
    return {
      main: Number((data.pipe * literToUnit) / yearToHour).toFixed(precision),
      service: Number((data.service * literToUnit) / yearToHour).toFixed(
        precision
      ),
      customerSide: Number(
        (data.customer_side * literToUnit) / yearToHour
      ).toFixed(precision),
      meter: Number((data.meter * literToUnit) / yearToHour).toFixed(precision),
      valve: Number((data.valve * literToUnit) / yearToHour).toFixed(precision),
      hydrant: Number((data.hydrant * literToUnit) / yearToHour).toFixed(
        precision
      ),
      fitting: Number(
        (data.customer_fitting * literToUnit) / yearToHour
      ).toFixed(precision),
      curbstop: Number((data.curbstop * literToUnit) / yearToHour).toFixed(
        precision
      ),
    };
  };

  const displayConvertedValues = (res) => {
    let leakValues = {};

    if (selectedUnit === "Imperial US") {
      leakValues = handleLeakTypeConversion(
        res.data,
        literToGallon,
        yearToHour,
        2
      );
    } else if (selectedUnit === "Standard Metric") {
      leakValues = handleLeakTypeConversion(res.data, 1, yearToHour, 3);
    } else {
      leakValues = handleLeakTypeConversion(
        res.data,
        literToMetricCube,
        yearToHour,
        6
      );
    }

    setUserMain(leakValues.main);
    setUserService(leakValues.service);
    setUserCustomerSide(leakValues.customerSide);
    setUserMeter(leakValues.meter);
    setUserValve(leakValues.valve);
    setUserHydrant(leakValues.hydrant);
    setUserCustomerFitting(leakValues.fitting);
    setUserCurbstop(leakValues.curbstop);

    dispatch(setDisplayedAvgLeakSizeValues(leakValues));
  };

  const getLeakTypeValues = async () => {
    try {
      const res = await axios.get("/metrics-values", {
        params: {
          userId: loginValues.userId,
          sub_project: projectLeak,
        },
      });

      displayConvertedValues(res);
    } catch (err) {
      console.log(err);
    }
  };

  const setUserLeakTypeValues = async () => {
    if (
      projectLeak === "" ||
      userMain === "" ||
      userHydrant === "" ||
      userValve === "" ||
      userService === "" ||
      userMeter === "" ||
      userCustomerFitting === "" ||
      userCurbstop === ""
    ) {
      return;
    }

    const unitToLiter = unitToLiterConversions[selectedUnit];

    const adjustedMain = userMain * unitToLiter * yearToHour;
    const adjustedHydrant = userHydrant * unitToLiter * yearToHour;
    const adjustedValve = userValve * unitToLiter * yearToHour;
    const adjustedService = userService * unitToLiter * yearToHour;
    const adjustedMeter = userMeter * unitToLiter * yearToHour;
    const adjustedCustomerFitting =
      userCustomerFitting * unitToLiter * yearToHour;
    const adjustedCurbstop = userCurbstop * unitToLiter * yearToHour;
    const adjustedCustomerSide = userCustomerSide * unitToLiter * yearToHour;

    try {
      dispatch(setLoading(true));
      const res = await axios.post(`/metrics-values`, {
        data: {
          sub_project: projectLeak,
          pipe: adjustedMain,
          hydrant: adjustedHydrant,
          valve: adjustedValve,
          service: adjustedService,
          meter: adjustedMeter,
          customer_fitting: adjustedCustomerFitting,
          curbstop: adjustedCurbstop,
          customer_side: adjustedCustomerSide,
          unit_type: selectedUnit,
        },
        userId: loginValues.userId,
      });
      if (res.status === 200) {
        displayConvertedValues(res);
        dispatch(setLoading(false));
        dispatch(setSnackBarMsg(t("settings-leak-saved")));
      }
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
      dispatch(setSnackBarMsg(t("settings-error")));
    }
  };

  const resetLeakTypeValues = async () => {
    try {
      const res = await axios.get("/metrics-values", {
        params: {
          userId: loginValues.userId,
          sub_project: 0,
        },
      });

      displayConvertedValues(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <UserRoute>
      <Layout goToSubProject={goToSubProject}>
        <Wrapper>
          <HeaderSection>
            <BackButton />
            <h1>{t("my-account.h1")}</h1>
          </HeaderSection>
          <TabSection>
            <div className="tab-section">
              {buttons.map((button) => (
                <button
                  key={button.id}
                  onClick={() => handleTabClick(button.id)}
                  id="sections"
                  className={tabSection === button.id ? "active" : ""}
                >
                  {button.name}
                </button>
              ))}
            </div>
            {tabSection === "settings" && (
              <SettingsTab>
                <div className="settings-container">
                  <form>
                    <div className="settings-header">
                      {t("system-settings.basicSettings")}
                    </div>
                    <div className="settings-options">
                      <div className="setting-type">
                        {t("system-settings.language")}
                      </div>
                      <select
                        className="selected-option"
                        value={selectedLanguage}
                        onChange={chooseLanguage}
                      >
                        {languageMap.map((lang, index) => {
                          const smallLang = langShortNames[index].short;
                          return (
                            <option key={index} value={smallLang}>
                              {lang}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="settings-options">
                      <div className="setting-type">
                        {t("system-settings.currency")}
                      </div>
                      <select
                        className="selected-option"
                        value={selectedCurrency}
                        onChange={chooseCurrency}
                      >
                        {currencies.map((curr, index) => {
                          return (
                            <option key={index} value={curr}>
                              {curr}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="settings-options">
                      <div className="setting-type">
                        {t("system-settings.units")}
                      </div>
                      <select
                        className="selected-option"
                        value={selectedUnit}
                        onChange={chooseUnit}
                      >
                        <option value="Imperial US">
                          {t("system-settings.us")}
                        </option>
                        <option value="Standard Metric">
                          {t("system-settings.metric")}
                        </option>
                        <option value="Metric Cubic Meters">
                          {t("system-settings.cubic")}
                        </option>
                      </select>
                    </div>
                    <br />
                    <br />
                    <div className="settings-header">
                      {t("system-settings.costs")}
                    </div>
                    <div className="settings-options">
                      <div className="setting-type">
                        {t("system-settings.water")}
                      </div>
                      <input
                        className="selected-option"
                        type="number"
                        value={displayedWaterCost}
                        placeholder={`Previous Cost ${previousWaterCost}`}
                        onChange={chooseWaterCost}
                      />
                      <span className="cost-unit">
                        {selectedCurrency === "USD $"
                          ? ` ${"$"} `
                          : ` ${selectedCurrency} `}
                        {selectedUnit === "Imperial US"
                          ? `/ gal`
                          : selectedUnit === "Standard Metric"
                          ? `/ liter`
                          : selectedUnit === "Metric Cubic Meters"
                          ? `/ m³`
                          : null}
                      </span>
                    </div>
                    <div className="settings-options">
                      <div className="setting-type">
                        {t("system-settings.power")}
                      </div>
                      <input
                        className="selected-option"
                        type="number"
                        value={displayedPowerCost}
                        placeholder={`Previous Cost ${previousPowerCost}`}
                        onChange={choosePowerCost}
                      />
                      <span className="cost-unit">
                        {selectedCurrency === "USD $"
                          ? ` ${"$"} `
                          : ` ${selectedCurrency} `}
                        {`/ kW`}
                      </span>
                    </div>
                    <div className="settings-options">
                      <div className="setting-type">
                        {t("system-settings.greenhouse")}
                      </div>
                      <input
                        className="selected-option"
                        type="number"
                        value={displayedGreenhouseGas}
                        placeholder={`Previous Cost ${previousGreenhouseEmissions}`}
                        onChange={chooseGreenhouseGas}
                      />
                      <span className="cost-unit">MT / kWh</span>
                    </div>
                    <br />
                    <br />
                    <div className="settings-options">
                      <div className="setting-type">
                        {t("system-settings.projects")}
                      </div>
                      <select
                        className="selected-option"
                        value={project}
                        onChange={chooseProject}
                        onClick={() =>
                          project && retrieveWaterPowerGreenCosts()
                        }
                      >
                        <option value="">{t("system-settings.service")}</option>
                        {subProjectRecoverArr &&
                          subProjectRecoverArr.length > 0 &&
                          Object.keys(subProjectRecoverArr).map((index) => {
                            const project = subProjectRecoverArr[index].title;
                            const sub_project =
                              subProjectRecoverArr[index].sub_project;
                            return (
                              <option key={index} value={sub_project}>
                                {project}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="button-row">
                      <button
                        className="apply-button"
                        type="button"
                        onClick={handleClick}
                      >
                        {t("system-settings.apply")}
                      </button>
                    </div>
                  </form>
                </div>
              </SettingsTab>
            )}
            {tabSection === "leak sizes" && (
              <SettingsTab>
                <div className="settings-container">
                  <form>
                    <div className="averageLeakSizeHeader">
                      Average Leak Size
                    </div>
                    <LeakMetricInput
                      title={"Main"}
                      valueToUpdate={userMain}
                      displayedPreviousValue={
                        displayedAvgLeakSizeValues?.main || ""
                      }
                      chooseFunction={(e) => setUserMain(e.target.value)}
                      selectedUnit={selectedUnit}
                    />
                    <LeakMetricInput
                      title={"Service"}
                      valueToUpdate={userService}
                      displayedPreviousValue={
                        displayedAvgLeakSizeValues?.service || ""
                      }
                      chooseFunction={(e) => setUserService(e.target.value)}
                      selectedUnit={selectedUnit}
                    />
                    <LeakMetricInput
                      title={"Customer Side"}
                      valueToUpdate={userCustomerSide}
                      displayedPreviousValue={
                        displayedAvgLeakSizeValues?.customerSide || ""
                      }
                      chooseFunction={(e) =>
                        setUserCustomerSide(e.target.value)
                      }
                      selectedUnit={selectedUnit}
                    />
                    <LeakMetricInput
                      title={"Meter"}
                      valueToUpdate={userMeter}
                      displayedPreviousValue={
                        displayedAvgLeakSizeValues?.meter || ""
                      }
                      chooseFunction={(e) => setUserMeter(e.target.value)}
                      selectedUnit={selectedUnit}
                    />
                    <LeakMetricInput
                      title={"Valve"}
                      valueToUpdate={userValve}
                      displayedPreviousValue={
                        displayedAvgLeakSizeValues?.valve || ""
                      }
                      chooseFunction={(e) => setUserValve(e.target.value)}
                      selectedUnit={selectedUnit}
                    />
                    <LeakMetricInput
                      title={"Hydrant"}
                      valueToUpdate={userHydrant}
                      displayedPreviousValue={
                        displayedAvgLeakSizeValues?.hydrant || ""
                      }
                      chooseFunction={(e) => setUserHydrant(e.target.value)}
                      selectedUnit={selectedUnit}
                    />
                    <LeakMetricInput
                      title={"Fitting"}
                      valueToUpdate={userCustomerFitting}
                      displayedPreviousValue={
                        displayedAvgLeakSizeValues?.fitting || ""
                      }
                      chooseFunction={(e) =>
                        setUserCustomerFitting(e.target.value)
                      }
                      selectedUnit={selectedUnit}
                    />
                    <LeakMetricInput
                      title={"Curbstop"}
                      valueToUpdate={userCurbstop}
                      displayedPreviousValue={
                        displayedAvgLeakSizeValues?.curbstop || ""
                      }
                      chooseFunction={(e) => setUserCurbstop(e.target.value)}
                      selectedUnit={selectedUnit}
                    />
                    <br />
                    <br />
                    <div className="settings-options">
                      <div className="setting-type">
                        {t("system-settings.projects")}
                      </div>
                      <select
                        className="selected-option"
                        value={projectLeak}
                        onChange={(e) => setProjectLeak(e.target.value)}
                        onClick={() => projectLeak && getLeakTypeValues()}
                      >
                        <option value="">{t("system-settings.service")}</option>
                        {subProjectRecoverArr &&
                          subProjectRecoverArr.length &&
                          Object.keys(subProjectRecoverArr).map((index) => {
                            const project = subProjectRecoverArr[index].title;
                            const sub_project =
                              subProjectRecoverArr[index].sub_project;
                            return (
                              <option key={index} value={sub_project}>
                                {project}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="button-row">
                      <button
                        className="apply-button"
                        type="button"
                        onClick={setUserLeakTypeValues}
                      >
                        {t("system-settings.apply")}
                      </button>
                    </div>
                  </form>
                  <div className="reset-button-column">
                    <button
                      className="reset-button"
                      type="button"
                      onClick={resetLeakTypeValues}
                    >
                      {t("system-settings.reset")}
                    </button>
                  </div>
                </div>
              </SettingsTab>
            )}
            {tabSection === "contact" && (
              <ContactTab>
                <div className="account card">
                  <p>
                    <span className="mid-weight uppercase">
                      {t("account-admin")}
                    </span>
                    <br />
                    {loginValues &&
                      loginValues.first_name &&
                      `${loginValues.first_name} ${loginValues.last_name}`}
                  </p>
                  <p>{loginValues && loginValues.name}</p>
                  <ul className="contact-list">
                    <li className="contact-item">
                      {loginValues && loginValues.phone_number && "Cell: "}
                      {loginValues &&
                        loginValues.phone_number &&
                        loginValues.phone_number}
                    </li>
                    <li className="email-item">
                      {loginValues && loginValues.email && loginValues.email}
                    </li>
                  </ul>
                </div>
              </ContactTab>
            )}
            {tabSection === "licenses" && (
              <LicensesTab>
                <Licenses licenseData={licenses} />
              </LicensesTab>
            )}
          </TabSection>
        </Wrapper>
      </Layout>
    </UserRoute>
  );
};
export default Account;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "account", "buttons", "navigation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
